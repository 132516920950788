import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes } from '../../global.d';
import { setIsSlotBusy, setIsSpinInProgress, setProgress, setReplayBet, setSlotConfig } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { BgmControl } from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import { IntroScreen } from '../../slotMachine/introScreen';
import { nextTick, wrap } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import ReplaySpin from '../ReplaySpin';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

const playBgm = () => {
  if (AudioApi.restrictionChangedOnIntroScreen) return;
  AudioApi.restrictionChangedOnIntroScreen = true;

  nextTick(() => {
    //setProgress({ ...setProgress(), wasLoaded: true });
    //if (!AudioApi.isInitialized) {
    //  eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
    //}
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
      listToPlay: [],
      onChangeRestriction: () => {
        BgmControl.handleChangeRestriction();
      },
    });
    //eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
  });
};

const GameScreen: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn } = data!;
  const [, setShowContent] = useState(false);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });
    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resizeHandler = (): void => {
      const userAgent = navigator.userAgent;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          resize();
        }, 50);
      } else {
        resize();
      }
    };

    const initSlotMachine = () => {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
    };

    IntroScreen.init(application);

    if (!setReplayBet()) {
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        initSlotMachine();
        resize();
      });
    } else {
      // replay start
      initSlotMachine();
      playBgm();
    }

    resize();

    window.addEventListener(EventTypes.RESIZE, resizeHandler);
    return () => window.removeEventListener(EventTypes.RESIZE, resizeHandler);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles.canvas} ${progress.wasLoaded ? '' : styles.intro}`} />
      <EventListener />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsPopup />
      {setReplayBet() ? <ReplaySpin /> : <Spin />}
    </>
  );
};

export default GameScreen;
