/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { BetBonusReward, EventTypes, GameMode, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  replayBetGql,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setIsBuyFeaturePopupOpened,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsOpenedMessageBanner,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSlotConfig,
  setStressful,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { canPressSpin, isFreeSpinMode, showCurrency } from '../../utils';

const ReplaySpin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;

  const { progress } = dataProgress!;
  const stressful = useReactiveVar(setStressful);

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [getReplayBet, { client }] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError() {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
    },
  });

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<unknown>): void => {
    eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
    const clonedPlaceBet = JSON.parse(JSON.stringify(placeBet)) as ISettledBet;

    if (clonedPlaceBet.rewards) {
      const replayBonusIndex = clonedPlaceBet?.rewards.findIndex((reward) => reward.__typename === 'ReplayBonusReward');
      if (replayBonusIndex > -1) {
        clonedPlaceBet.rewards[replayBonusIndex]!.__typename = 'BetBonusReward';
        // retrigger
        if (setCurrentBonus().isActive && setCurrentBonus().currentRound > 0) {
          const replayBonus = clonedPlaceBet?.rewards.find(
            (reward) => reward.__typename === 'BetBonusReward',
          ) as BetBonusReward;
          if (replayBonus) {
            // The round numbers are different when replaying, so they are calculated
            const addRounds = replayBonus.userBonus!.rounds;
            clonedPlaceBet.bet.data!.bonuses = clonedPlaceBet.bet.data!.bonuses.map((bonus) => {
              bonus.rounds = setCurrentBonus().rounds + addRounds - setCurrentBonus().currentRound;
              bonus.roundsPlayed = setCurrentBonus().currentRound;
              return bonus;
            });
          }
        }
      }
    }

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: clonedPlaceBet.balance.placed,
        },
      },
    });

    SlotMachine.getInstance().setResult(clonedPlaceBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: clonedPlaceBet.balance.settled,
          },
        },
      });
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      const spinState = SlotMachine.getInstance().state;
      if (spinState !== SlotMachineState.IDLE || !isFreeSpinMode(setGameMode())) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinMode(setGameMode()) || setBrokenGame()) return;
        resetPopupsStateToClosed();
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        getReplayBet({
          variables: { betId: setReplayBet() },
        });

        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SONG_SFX_UI_Close });
        AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }
    },
    [lineSet, slotId],
  );

  useEffect(() => {
    const freeSpin = () => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayFreeSpinBets().length && setReplayFreeSpinBets().length > 1) {
        setReplayBet(setReplayFreeSpinBets()[setCurrentBonus().currentRound]);
      }
      getReplayBet({
        variables: { betId: setReplayBet() },
      });

      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
    };
    /* const buyFeatureSpin = () => {
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.BUY_FEATURE]!,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
    }; */

    eventManager.addListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    //eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    return () => {
      //eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const onSpinButtonClick = useCallback(() => {
    if ((setGameMode() === GameMode.REGULAR || setGameMode() === GameMode.BUY_FEATURE) && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    onSpin(isTurboSpin);
  }, [isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (stressful.show) {
          return;
        }

        eventManager.emit(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER);

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isAutoSpins: false,
            isFadeOut: setIsFadeOut(),
            isBrokenBuyFeature: setBrokenBuyFeature(),
          })
        ) {
          return;
        }

        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [gameMode, dataSlotStopped?.isSlotStopped, progress?.wasLoaded, onSpin, isTurboSpin, stressful],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    eventManager.addListener(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isTurboSpin]);

  return null;
};

export default ReplaySpin;
