import { gql } from '@apollo/client';

import { queryParams } from '../utils';

import {
  BET,
  BET_RESULT,
  BONUS,
  LINE_SET,
  PAGE_INFO,
  PAYLINE,
  REEL_SET,
  REPLAY_BET,
  USER_BALANCE,
  USER_BONUS,
} from './fragments';

export const getUserGql = gql`
  query GetUser {
    user {
      id
      clientId
      balance {
        amount
        currency
      }
    }
  }
`;

export const slotHistoryGql = gql`
  ${PAGE_INFO}
  ${BET}
  ${BET_RESULT}
  query slotHistory($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
      edges {
        cursor
        node {
          ...bet
          result {
            ...betResult
          }
          userBonus {
            bonusId
          }
        }
      }
    }
  }
`;

export const userBonusBetsGql = gql`
  ${PAGE_INFO}
  query userBonusBets($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
    }
  }
`;

export const slotBetGql = gql`
  ${BET}
  ${BET_RESULT}
  query ${queryParams.has('replayBetId') ? 'uselessReplayBet' : 'bet'}($input: GetBetInput!) {
    bet(input: $input) {
      ...bet
      result {
        ...betResult
      }
    }
  }
`;

export const getSlotGql = gql`
  query getSlot($input: GetSlotInput!) {
    slot(input: $input) {
      id
      reels {
        id
        type
        layout
      }
      lines
      icons {
        id
        type
        combos {
          type
          pattern
          rewards {
            type
            multiplier
            count
            bonusId
          }
          payoffType
        }
      }
      previewImage
      settings {
        betLines {
          max
          min
        }
        startPosition
      }
      clientSettings {
        coinAmounts {
          default
          quick
        }
        coinValues {
          code
          variants
        }
        features {
          id
          enabled
        }
        autoplay {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
              multipliers
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
      }
      lineSets {
        id
        slotId
        lines
        coinAmountMultiplier
      }
    }
  }
`;

export const getBonuses = gql`
  query GetBonuses($input: GetBonusesInput) {
    bonuses(input: $input) {
      id
      type
      coinAmount
    }
  }
`;

export const getUserBonuses = gql`
  ${USER_BONUS}
  query GetUserBonuses($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      ...userBonus
    }
  }
`;

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    soundValue @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isShowSoundToast @client
    isShowSuspendedToast @client
    isOpenAutoplayPopup @client
    isOpenBetSettingsPopup @client
    isOpenHistoryPopup @client
    isOpenInfoPopup @client
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;

export const getGameModeGql = gql`
  query GetAutoSpin {
    gameMode @client
  }
`;

export const getSlotConfigGql = gql`
  query GetSlotConfig {
    slotConfig @client {
      id
      lines
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
  }
`;

export enum ReelSetType {
  DEFAULT = 'DEFAULT',
  BONUS = 'BONUS',
}
export const replayBetGql = gql`
  ${REPLAY_BET}
  ${REEL_SET}
  ${LINE_SET}
  ${BONUS}
  ${BET_RESULT}
  ${PAYLINE}
  ${USER_BALANCE}
  ${USER_BONUS}
  query replayBet($betId: ID!) {
    placeBet: replayRound(betId: $betId) {
      bet {
        ...bet
        reelSet {
          ...reelSet
        }
        lineSet {
          ...lineSet
        }
        userBonus: replayUserBonus {
          bonus {
            ...bonus
          }
        }
        result {
          ...betResult
        }
      }
      paylines {
        ...payline
      }
      balance {
        placed {
          ...userBalance
        }
        settled {
          ...userBalance
        }
      }
      rewards {
        ... on ReplayBonusReward {
          userBonusId
          userBonus: replayUserBonus {
            ...userBonus
            bonus {
              ...bonus
            }
          }
        }
        ... on BetCoinReward {
          amount
        }
      }
    }
  }
`;

export const betsByInitialRoundIdGql = gql`
  query betsByInitialRoundId($initialRoundId: UUID!) {
    betsByInitialRoundId(initialRoundId: $initialRoundId) {
      id
      result {
        reelPositions
        winCoinAmount
      }
      reelSetId
    }
  }
`;
